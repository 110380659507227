import request from '@/utils/request'
// 分页查询团购商品信息列表
export function getTableList(data) {
  return request('post', '/apm/dianping/goods/list', data)
}

// 同步团购商品信息列表
export function getRefreshList(data) {
  return request('post', '/apm/dianping/goods/sync', data)
}

// 根据商品查询门店列表
export function getShopList(data) {
  return request('get', '/apm/tuangou/update/bind/detail?goodsId=' + data.goodsId + "&tuanGouType=" + data.tuanGouType)
}
// 根据商品查询门店列表2 
export function getTuangouShopList(data) {
  return request('get', '/apm//tuangou/shopByOpenShopUuid?tuanGouShopId='+data.tuanGouShopId+"&tuanGouType=" + data.tuanGouType)
}

// 查询修改绑定卡券列表
export function getCardList(data) {
  return request('get', '/apm/tuangou/tuanGouCardCouponList?shopId=' + data.shopId + "&serviceId=" + data.serviceId + "&cardType=" + data.cardType)
}

// 同步团购商品信息列表
export function getCardBind(data) {
  return request('post', '/apm/dianping/goods/bind', data)
}